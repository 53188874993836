<template>
  <div>
    <div style="min-height: 4px">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="pending"
      ></v-progress-linear>
    </div>

    <v-sheet class="pa-4 pt-0">
      <div class="pt-4">
        <p>На графике представлены баллы за {{ currentYear }} год</p>
        <div>
          Выбранный диапазон баллов: от {{ rangeStats.min }} до
          {{ rangeStats.max }}
        </div>
        <div>
          Количество участников: <strong>{{ rangeStats.count }}</strong>
          <span class="text-caption ml-2"
            >({{ rangeStats.percentage }}% из всех участников с ненулевыми
            баллами)</span
          >
        </div>
      </div>
      <s-chart
        v-show="scores.length"
        :scores="scores"
        @rangeChange="debouncedSetRange"
      />
    </v-sheet>
    <div v-if="!pending && !scores.length" class="py-4">
      Нет данных о набранных баллах
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import SChart from "@/components/scores/SChart";
const currentYear = new Date().getFullYear();
import debounce from "lodash/debounce";
export default {
  name: "ScoresChart",
  components: {
    SChart,
  },
  data() {
    return {
      filters: {
        year: currentYear,
        hide_zeros: true,
      },
      scores: [],
      pending: false,
      range: [0, 1],
      currentYear,
    };
  },
  computed: {
    compId: {
      get() {
        return parseInt(this.$route.params.id);
      },
      set(id) {
        this.$router.push({
          name: "competence_scores_chart",
          params: {
            id,
          },
        });
      },
    },
    scoreStats() {
      return this.scores.reduce(
        (acc, item) => {
          acc.min = acc.min ? Math.min(acc.min, item.score) : item.score;
          acc.max = Math.max(acc.max, item.score);
          acc.total += item.count;
          return acc;
        },
        {
          min: 0,
          max: 0,
          total: 0,
        }
      );
    },
    rangeStats() {
      const { min, max } = this.scoreStats;
      const [start, end] = this.range;
      const leftScores =
        start === 0 ? min : Number((min + (max - min) * start).toFixed(3));
      const rightScores =
        end === 1 ? max + 1 : Number((min + (max - min) * end).toFixed(3));

      const usersCount = this.scores.reduce((acc, value) => {
        if (value.score >= leftScores && value.score < rightScores) {
          acc += value.count;
        }
        return acc;
      }, 0);

      return {
        min: leftScores,
        max: end === 1 ? max : rightScores,
        count: usersCount,
        percentage: Math.round((usersCount / this.scoreStats.total) * 100),
      };

      // left value: MIN + (MAX - MIN)*scrollbarX.range.start;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getScores();
      },
    },
    compId: {
      handler() {
        this.getScores();
      },
    },
  },
  created() {
    this.debouncedSetRange = debounce(this.handleRangeChange, 600);
    this.getScores();
  },
  methods: {
    handleRangeChange(range) {
      this.range = range;
    },
    async getScores() {
      if (this.pending) return;
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/scores/distribution",
          params: {
            competences_ids: this.compId,
            years: this.filters.year,
            hide_zeros: this.filters.hide_zeros,
          },
        });
        this.scores = data;
      } catch (error) {
        console.log("error", error);
        this.error = error.message;
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
