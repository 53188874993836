<template>
  <div ref="chartdiv" style="height: 600px"></div>
</template>

<script>
import { create, Circle, useTheme } from "@amcharts/amcharts4/core";
import {
  ValueAxis,
  XYChart,
  LineSeries,
  XYCursor,
  XYChartScrollbar,
  Bullet,
} from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
useTheme(am4themes_animated);
export default {
  name: "SChart",
  props: {
    scores: {
      type: Array,
    },
  },
  watch: {
    scores: {
      handler(val) {
        if (val && this.chart) {
          this.chart.data = val;
        }
      },
      deep: true,
    },
  },
  mounted() {
    let chart = create(this.$refs.chartdiv, XYChart);
    chart.paddingRight = 20;
    chart.data = this.scores;
    let scoreAxes = chart.xAxes.push(
      new ValueAxis({
        strictMinMax: true,
      })
    );
    scoreAxes.renderer.grid.template.location = 0;
    scoreAxes.title.text = "Набранные баллы";
    scoreAxes.strictMinMax = true;

    let valueAxis = chart.yAxes.push(new ValueAxis());
    // valueAxis.tooltip.disabled = true;
    // valueAxis.renderer.minWidth = 35;
    valueAxis.title.text = "Количество участников";

    let series = chart.series.push(new LineSeries());
    series.dataFields.valueX = "score";
    series.dataFields.valueY = "count";

    series.tooltipText = "{valueY.value}";
    chart.cursor = new XYCursor();

    let scrollbarX = new XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
    // Add simple bullet
    const bullet = series.bullets.push(new Bullet());
    const square = bullet.createChild(Circle);
    square.width = 6;
    square.height = 6;
    square.horizontalCenter = "middle";
    square.verticalCenter = "middle";
    this.chart = chart;

    scrollbarX.events.on("rangechanged", ({ target }) => {
      // left value: MIN + (MAX - MIN)*scrollbarX.range.start;
      // right value: MAX;
      // console.log("abc", target.start, target.end, scrollbarX.range, target);
      this.$emit("rangeChange", [target.start, target.end]);
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style></style>
